import React from "react";
import { Label } from "reactstrap";
import { Calendar } from "primereact/calendar";
import "./styles.scss";

const DateInputRange = ({
  label,
  required,
  subtitle,
  value,
  onChange,
  name,
  id,
  placeholder,
}) => {
  return (
    <div>
      {label && (
        <Label className="heading_4" htmlFor={name}>
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <Calendar variant={undefined} placeholder={placeholder} className="input" id={id} value={value} onChange={onChange} selectionMode="range" readOnlyInput hideOnRangeSelection dateFormat={"dd/mm/yy"} />
      {subtitle && (
        <div
          style={{ marginTop: 5 }}
          className="bodyText_11 input_subtitle_text"
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default DateInputRange;
