import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../../../Components/Common/Inputs/TextInput/TextInput";
import debounce from "../../../../infra/utils/debounce";


import "../../styles.scss";
import DateInputRange from "../../../../Components/Common/Inputs/DateInputRange/DateInputRange";
const SmartOrdersFilters = ({ filters, onFilterChange }) => {
  const [localFilters, setLocalFilters] = useState(filters);
  const [dates, setDates] = useState(null);
  const [datesErrorMsg, setDatesErrorMsg] = useState(false);

  const { t } = useTranslation();

  const debouncedFilterChange = useCallback(
    debounce((name, value) => {
      onFilterChange(name, value);
    }, 1000),
    [onFilterChange]
  );

  const handleLocalFilterChange = (name, value) => {
    setLocalFilters((prev) => ({ ...prev, [name]: value }));
    debouncedFilterChange(name, value);
  };

  const handleGFilterChange = (filter) => {
    onFilterChange(filter, !filters[filter]);
  };
  const handleDateRangeChange = (dates) => {

    setDatesErrorMsg(false);

    if (dates && dates.length === 2) {
      const startDate = dates[0];
      const endDate = dates[1];
      const oneMonthLater = new Date(startDate);
      oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

      setDates(dates);
      if (endDate > oneMonthLater) {
        onFilterChange("dates", dates);
      } else {
        if (endDate) {
          setDatesErrorMsg(true);
        }
      }
    }
  };
  // Required for Clear All button (clears filters and labels)
  // const handleClearAll = () => {
  //   setLocalFilters({});
  //   onFilterChange({});
  //   setValue([]);
  // };

  // Required for filter label display
  // const handleRemoveFilter = (filterName) => {
  //   const newFilters = { ...localFilters };
  //   delete newFilters[filterName];
  //   setLocalFilters(newFilters);
  //   onFilterChange(newFilters);
  // };

  return (
    <div
      className="smart-orders-filter-wrapper"
      style={{
        maxHeight: "calc(100vh - 185px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div className="d-flex flex-row justify-content-between">
        <div className="bodyText_2">{t("FILTERS")}</div>

        {/*  */}
        {/* Labels and clear all can be added when filters like rating are implemented */}
        {/*  */}

        {/* <div
          className="heading_5 text-info text-decoration-underline cursor-pointer"
          onClick={handleClearAll}
        >
          {t("CLEAR_ALL")}
        </div> */}
      </div>

      {/* Labels */}
      {/* <div className="filter-labels-wrapper">
        {Object.entries(localFilters).map(
          ([key, value]) =>
            value && (
              <div className="d-inline-block label-gap" key={key}>
                <div className="filter-label">
                  <div className="bodyText_11 text-white">{`${t(
                    key.toUpperCase()
                  )}: ${value}`}</div>
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <div className="labels-divider-vertical"></div>
                    <Icon
                      style={{ cursor: "pointer" }}
                      icon="closeIcon"
                      size={[16, 16]}
                      color="white"
                      onClick={() => handleRemoveFilter(key)}
                    />
                  </div>
                </div>
              </div>
            )
        )}
      </div> */}

      <div className="divider" />

      <div className="filter-container">
        <div className="filter-item">
          <div
            className="heading_3 mb-1"
            style={{ color: "rgba(68, 79, 89, 0.60)" }}
          >
            {`${t("CLIENT")}`}
          </div>
          <div >
            <TextInput
              placeholder={`${t("CLIENT")}...`}
              type="number"
              onChange={(e) =>
                handleLocalFilterChange("client", e.target.value)
              }
              value={localFilters.client || ""}
              icon="searchIcon"
              iconSize={[14, 14]}
              noBorder
            />
          </div>
        </div>

        <div className="filter-item">
          <div
            className="heading_3 mb-1"
            style={{ color: "rgba(68, 79, 89, 0.60)" }}
          >
            {t("REFERENCE_OR_SKU")}
          </div>
          <div >
            <TextInput
              placeholder={`${t("SEARCH_REFERENCE_OR_SKU")}...`}
              type="text"
              onChange={(e) =>
                handleLocalFilterChange("referenceOrSku", e.target.value)
              }
              value={localFilters.referenceOrSku || ""}
              icon="searchIcon"
              iconSize={[14, 14]}
              noBorder
            />
          </div>
        </div>

        <div className="filter-rating">

          <div
            className="heading_3 mb-1"
            style={{ color: "rgba(68, 79, 89, 0.60)" }}
          >
            {t("RATING")}
          </div>
          <div className="rating-buttons">
            {["G1", "G2", "G3", "G4"].map((filter) => (
              <button
                key={filter}
                className={`rating-button ${filters[filter] ? "active" : ""}`}
                onClick={() => handleGFilterChange(filter)}
              >
                {filter}
              </button>
            ))}
          </div>
        </div>
        <div className="filter-item relative">
          <div
            className="heading_3 mb-1"
            style={{ color: "rgba(68, 79, 89, 0.60)" }}
          >
            {t("DATE_RANGE")}
          </div>
          <DateInputRange

            value={dates}
            onChange={(e) => {
              handleDateRangeChange(e.value);
            }
            } />
          {datesErrorMsg && <div
            className="heading_3 mb-1 error_msg"
            style={{ color: "rgba(196, 17, 20, 0.769)" }}
          >
            {t("SELECT_MORE_THAN_MONTH")}
          </div>}
        </div>
      </div>
    </div>
  );
};

export default SmartOrdersFilters;
